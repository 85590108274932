<template>
<Perfil />
</template>


<script>
import Perfil from '@/components/Perfil.vue'

export default {
 
  name: 'Perfiles',
  
  components: {
    Perfil, 
  },

  data: () => ({
  //
  }),

};
</script>

<style>

</style>