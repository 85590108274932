<template>
<div class="js-slider stage-video block-stage block-stage--regular stage-video--disable-nav" data-slider-stage="" data-slider-stage-disable="stage-video--disable-nav" data-slider-video="" data-slider-autoplay="true" data-slider-stage-playing="stage-video--playing">
    <div class="js-slider-items block-stage__items stage-video__items slick-initialized slick-slider">
        <div aria-live="polite" class="slick-list draggable">
            <div class="slick-track" style="opacity: 1; width: 1903px; transform: translate3d(0px, 0px, 0px);" role="listbox">
                <div class="block-stage__item slick-slide slick-current slick-active" style="width: 1903px;" tabindex="-1" role="option" aria-describedby="slick-slide00" data-slick-index="0" aria-hidden="false">
                    <picture class="block-stage__picture block-stage__picture--only">
                        <source media="(max-width: 360px)" srcset="/imagenes/Perfil/5.png">
                        <source media="(max-width: 479px)" srcset="/imagenes/Perfil/4.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/Perfil/3.png">
                        <source media="(max-width: 1024px)" srcset="/imagenes/Perfil/2.png">
                        <source media="(min-width: 1025px)" srcset="/imagenes/Perfil/1.png">
                        <img src="" alt="DB Schenker Flags in the Sky" class="block-stage__img">
                    </picture>
                    <div class="block-stage__content wrapper wrapper--gutter"></div>
                </div>
            </div>
        </div>
    </div>
</div>

    <section class="section has-margin">
        <div class="wrapper wrapper--gutter">
            <div class="grid">
                <div class="grid__item portable--two-thirds">
                    <div class="cm-teasable">
                        <h2 class="cm-teasable__headline">Perfil</h2>
                        <div class="cm-teasable__text">
                            <h3 class="h3">¿Quién es Sunrise Cargo?&nbsp;</h3>
                            <p>Sunrise Cargo es una agencia de carga internacional, aliada con proveedores de logística globales líderes en el mundo: apoyamos a la industria y el comercio en el intercambio global de bienes a través de transporte terrestre, transporte aéreo y marítimo, logística de contratos y gestión de la cadena de suministro.&nbsp;</p> 
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </section>

    <section class="section facts-image-teaser section--bg">
    <picture class="facts-image-teaser__image facts-image-teaser__image--right" style="margin-right: 300px;">
        <img alt="" class="image-fits-area" src="/imagenes/Perfil/MapaColombia.svg">
    </picture>
    <div class="wrapper wrapper--gutter">
        <div class="grid">
            <div class="grid__item landscape--two-thirds">
                <h3 class="h3">Sunrise Cargo en Colombia</h3>
                <p>La logística integrada reside en las intersecciones más importantes del país, donde el flujo de mercancías crea un vínculo eficaz entre los transportistas.
Nuestros servicios de valor agregado aseguran que el flujo de bienes continúe sin problemas y que las cadenas de suministro se mantengan esbeltas y optimizadas para el éxito. </p>
                <div class="facts-image-teaser__facts-wrapper">
                <div class="grid teasers-row-center">
                    <div class="grid__item portable--one-half">
                        <div class="fact ">
                            <p class="fact__headline">Tenemos</p>
                            <p class="fact__amount">
                            <span data-autoanimate="" data-animate-number="2" data-animate-number-separator="," data-animate-number-decimals="0">2</span>
                            </p>
                            <p class="fact__description">bodegas en Bogotá con capacidad de 17.000 m2</p>
                        </div>
                        <div class="fact ">
                            <p class="fact__headline">Contamos con</p>
                            <p class="fact__amount">
                            <span data-autoanimate="" data-animate-number="9" data-animate-number-separator="," data-animate-number-decimals="0">9</span>
                            </p>
                            <p class="fact__description">oficinas en las principales ciudades del país</p>
                            <br><br><br><br>
                        </div>
                    </div>
                    <div class="grid__item portable--one-half">
                        <div class="fact ">
                            <p class="fact__headline">Más de</p>
                            <p class="fact__amount">
                            <span data-autoanimate="" data-animate-number="200" data-animate-number-separator="," data-animate-number-decimals="0">200</span>
                            </p>
                            <p class="fact__description">empleados en todo el territorio nacional</p>
                        </div>
                        <div class="fact ">
                            <p>
                            - Oficina principal Bogotá <br>
                            - Oficina Satélite Aeropuerto El Dorado<br>
                            - Bodega Siberia<br>
                            - Oficina Medellín <br>
                            - Oficina Cali<br>
                            - Oficina Pereira<br>
                            - Oficina/Puerto Barranquilla<br>
                            - Oficina/Puerto Cartagena<br>
                            - Oficina/Puerto Buenaventura
                            </p>
                        </div>                        
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    </section>

    <section class="section facts-image-teaser ">
        <picture class="facts-image-teaser__image facts-image-teaser__image--left">
            <img alt="" class="image-fits-area" src="/imagenes/Perfil/AvionBarco.svg" style="margin-left: 300px;">
        </picture>




        <picture class="facts-image-teaser__image facts-image-teaser__image--right" style="margin-right: 300px;">
            <img alt="" class="image-fits-area" src="/imagenes/Perfil/Bodega.svg">
        </picture>

    </section>

    <hr class="hr">

</template>

<script>
export default {

}
</script>

<style>

</style>